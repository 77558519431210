<template>
  <section class="answers" v-if="mostRecentClosedQuestion">
    <h2>Antwoorden op {{mostRecentClosedQuestion}}</h2>
    <ul class="playerAnswers">
      <li v-for="(team, tid, idx) in teams" v-bind:key="tid" :class="tid === user.uid ? 'own' : ''">
        <h4>{{idx+1}}. {{ team }}</h4>
        <div class="signContent"
             :class="scores[mostRecentClosedQuestion] && scores[mostRecentClosedQuestion][tid] && scores[mostRecentClosedQuestion][tid].correct ? 'correct' : 'incorrect'"
        >{{ scores[mostRecentClosedQuestion] && scores[mostRecentClosedQuestion][tid] ? scores[mostRecentClosedQuestion][tid].answer : '🤷' }}
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Answers",
  computed: {
    ...mapState({
      user: (state) => state.user || null,
      scores: state => state.scores ? state.scores : {},
      teams: state => state.game.teams,
      mostRecentClosedQuestion: (state) => {
        if (state.scores) {
          const lastQuestion = Object.keys(state.scores).slice(-1)
          if (lastQuestion) {
            return lastQuestion[0]
          }
        }
        return null
      },
      currentQuestion: state => state.game.current_question
    })
  }
}
</script>

<style scoped>

section.answers {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 2rem;
  row-gap: 1rem;
}

section.answers > ul.playerAnswers {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fill,  minmax(clamp(12ch, 50%, 14ch), 1fr));
  grid-gap: 0.25rem;
}


ul.playerAnswers > li {
  animation: slidein 0.8s forwards;
  font-size: 0.9em;
}

@keyframes slidein {
  from {
    transform: scale(1.4) rotate(27deg);
    opacity: 0;
  }
  to {
    transform: scale(1) rotate(0);
    opacity: 1;
  }
}

ul.playerAnswers > li > h4 {
  font-size: 0.85rem;
  font-stretch: condensed;
}

ul.playerAnswers > li > div.signContent {
  height: 4rem;
  border-radius: 0.5rem;
  margin: 0;
  padding: 0;
  word-break: break-all;
  background-color: #e8e8e8;
  color: black;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  font-weight: bolder;

}

.correct {
  border: 0.35rem solid darkgreen;
}

.incorrect {
  border: 0.35rem solid darkred;
}

li.own > h4:after {
  content: " 🌟";
}
</style>