import firebase from 'firebase/app';// rollup bundle issue with ESM import
import 'firebase/auth';
import 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyDbCe6GJEqSOenCmpkCunDbiV8tSI_xp5k",
    authDomain: "frebee-7b2d8.firebaseapp.com",
    databaseURL: "https://frebee-7b2d8-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "frebee-7b2d8",
    storageBucket: "frebee-7b2d8.appspot.com",
    messagingSenderId: "730905147326",
    appId: "1:730905147326:web:4ba88c042cc2974841ebef"
}

firebase.initializeApp(firebaseConfig);

export let auth = firebase.auth();
export let emailProvider = new firebase.auth.EmailAuthProvider;
export let db = firebase.database();