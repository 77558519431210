<template>
  <main>
    <Sign v-if="game"></Sign>
    <Answers v-if="game"></Answers>
    <Score v-if="game"></Score>

  </main>
</template>

<script>
import {mapState} from "vuex";
import Sign from '@/components/sections/Sign'
import Score from '@/components/sections/Score'
import Answers from "@/components/sections/Answers";

export default {
  name: "Game",
  components: {Answers, Sign, Score},
  computed: mapState({
    answers: state => state.answers,
    game: state => state.game,
    scores: state => state.scores,
    user: state => state.user,
    enrolled: (state) => state.game && state.game.teams && state.game.teams[state.user.uid]
  }),
  beforeMount() {
    if (!this.user || !this.user.uid) {
      this.$router.push('/')
    }
    this.$store.dispatch('bindGameRef');
  },
}
</script>

<style scoped>


div#gameComponent h1 {
  padding-bottom: 1rem;
  text-transform: uppercase;
}

</style>