<template>
  <section class="reglement">
    <h2>Reglement</h2>
    <p>Bordjesquiz? Wat is dat? Het concept bestaat al een tijdje, en is in essentie niet zo moeilijk:
      Zoals met elke quiz krijg je vragen gesteld, en moet je die beantwoorden. Waar een klassieke quiz de antwoorden
      en score pas krijgt nadat een ronde is afgelopen, gebeurt dit bij een
      bordjesquiz onmiddellijk.
    </p>
    <p>Een juist antwoord levert punten op. Maar, elk antwoord is niet altijd 1 punt waard: spelen er 20 mensen mee, en
      heeft maar een iemand het juist, dan
      is dat 20 punten waard.
      Hebben 4 mensen het bij het juiste eind, dan is dat 5 punten waard. Hebben alle 20 het juist, dan natuurlijk
      maar 1 punt per antwoord.
    </p>
    <p>Slimmer zijn dan de rest loont dus. Ben je overtuigd? Doe dan gewoon mee!</p>
  </section>

</template>

<script>
export default {
  name: "Rules"
}
</script>

<style scoped>
.reglement {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-evenly;
  row-gap: 1rem;
  max-width: 48rem;
  grid-auto-rows: min-content;
  grid-gap: 2rem;
}

</style>