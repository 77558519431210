<template>
  <section>
    <div v-show="!user || (user && !user.uid)">
      Geef je email-adres in, en dan wordt er je een link toegestuurd waarmee je kan mee spelen.
      <div id="firebaseui-auth-container"></div>
    </div>
    <div v-show="user && user.uid">
      <div>Je bent aangemeld als {{ user.email }}</div>
      <button @click="signOut">Afmelden</button>
    </div>
  </section>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/app'
import * as firebaseui from "firebaseui";

import {auth} from '@/firebaseConfig'
import {mapState} from "vuex";


export default {
  name: "Login",
  data: function () {
    return {}
  },
  computed: {
    ...mapState({
      user: (state) => state.user || undefined,
      teams: state => state.game.teams,
    })
  },

  beforeMount() {
    this.ui = firebaseui.auth.AuthUI.getInstance();
    if (this.ui === null) {
      this.ui = new firebaseui.auth.AuthUI(firebase.auth());
    }

  },
  mounted: function () {
    const uiConfig = {
      signInSuccessUrl: '/',
      signInOptions: [{
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD
      }
      ],
    }
    this.ui.start('#firebaseui-auth-container', uiConfig);
  },
  methods: {
    signOut(e) {
      e.stopPropagation();
      this.$store.dispatch('signOut')
      auth.signOut();
      this.$router.push({
        name: "home"
      });
    },
  },
}
</script>

<style scoped>
section {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.5rem;
  place-content: end;
}

section > div {
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
}
button {
  width: clamp(8rem, 100vw, 16rem);
}
</style>