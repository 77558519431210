<template>
  <section>
    <label for="ourAnswer" id="signLabel">
      <span v-show="openForSubmission">
        Geef het antwoord op vraag
        <span class="highlight">{{ game.current_question }}</span>
      </span>
      <span v-show="!openForSubmission && game && game.current_question">
        We verbeteren nu
        <span class="highlight">{{ game.current_question }}</span>
      </span>
    </label>
    <div class="noQuestion" v-show="!(game && game.current_question)">
      <h1>Pauze</h1></div>
    <form v-show="game && game.current_question"
          ref="signForm"
          @submit="updateAnswer" >
        <textarea
            autocomplete="off" rows="3" cols="23"
            ref="ourAnswer" id="ourAnswer"
            placeholder="..." spellcheck="false"
            onclick="this.focus();this.select()"
            :disabled="!openForSubmission"
            :class="!openForSubmission ? 'blurred' : ''"
            v-model.trim="localAnswer"
            @keydown.enter.exact.prevent="updateAnswer"
        ></textarea>

        <button @click="shrug" id="shrugButton"
                :disabled="!openForSubmission">🤷</button>
        <button ref="submitButton"
                :disabled="sync === 'inSync' || !openForSubmission"
                type="submit"><span :class="sync"></span></button>

      <div class="preference">
        <h3>Jouw lokale voorkeuren</h3>
        <Toggle v-model="submitManual">Manueel indienen met Enter (ipv automagisch)
        </Toggle>
        <Toggle v-model="cleanSlate">Mijn bordje wissen als een nieuwe vraag begint
        </Toggle>
      </div>
    </form>


  </section>
</template>

<script>
import _ from 'lodash';
import {mapState} from 'vuex'
import Toggle from "@/components/elements/Toggle";


export default {
  name: "Sign",
  components: {Toggle},
  data: function () {
    return {
      localAnswer: "",
      submitManual: false,
      cleanSlate: true,
      sync: "inSync",
    }
  },
  mounted() {
    this.localAnswer = this.remoteAnswer;
    this.submitManual = localStorage.getItem(`prefs/submitManual`) === "true";
    this.cleanSlate = localStorage.getItem(`prefs/cleanSlate`) === "true";
  },
  computed: mapState({
    game: state => state.game,
    currentQuestion: state => state.game.current_question,
    remoteAnswer: (state) => {
      return state.answer ? state.answer.answer : null
    },
    openForSubmission: (state) => {
      return state.game ? state.game.open_for_submission : false
    },

  }),
  methods: {
    updateAnswer: function () {
      if (this.localAnswer) {
        this.sync = "outOfSync"
        this.$store.dispatch('writeAnswer', this.localAnswer.toUpperCase()).then(() => {
          // console.log(`answer updated successfully`)
          this.sync = "inSync"
        }, reason => {
          console.error("answer failed to update")
          console.error(reason)
          this.sync = "errorSync"
        })
      }
    },
    updateAnswerDebounce: _.debounce(function () {
      this.updateAnswer()
    }, 500),

    shrug: function (e) {
      e.stopPropagation()
      this.localAnswer = null;
      this.$store.dispatch('wipeAnswer').then(() => {
        this.sync = "inSync"
      }, reason => {
        console.error("answer failed to update")
        console.error(reason)
        this.sync = "errorSync"
      })

    }
  },
  watch: {
    cleanSlate(value) {
      localStorage.setItem(`prefs/cleanSlate`, value)
    },
    submitManual(value) {
      localStorage.setItem(`prefs/submitManual`, value)
    },
    remoteAnswer() {
      this.localAnswer = this.remoteAnswer;
    },
    localAnswer() {
      this.sync = 'outOfSync'
      if (!this.submitManual) {
        this.updateAnswerDebounce()
      }
    },
    currentQuestion: function (value) {
      console.log(`Question changed to ${value}. ${this.cleanSlate}`)
      if (this.cleanSlate) {
        this.$store.dispatch('wipeAnswer').then(() => {
          console.log('Wiped Sign')
          this.localAnswer = this.remoteAnswer;
          this.sync = 'inSync'
        })
      }
    },
    openForSubmission: function (isOpen) {
      if (isOpen) {
        this.$refs["ourAnswer"].focus()
        this.$refs["ourAnswer"].select()
      } else {
        this.$refs["ourAnswer"].blur()
        this.$refs["ourAnswer"].setSelectionRange(0, 0);
      }
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');

form {
  font-size: clamp(1rem, 4.4vw, 2.25rem);
  display: grid;
  grid-template-columns: 5fr 3fr 5fr;
  grid-gap: 1rem;
  grid-template-areas: "header header header"
                       "sign   sign   sign"
                       "space  shrug  submit"
                       "prefs  prefs  prefs";
}

h1 {
  font-size: 4rem;
  font-weight: 900;
  text-align: center;
  background-image: var(--background-gradient);
  color: transparent;
  -webkit-background-clip: text;
  text-shadow: 0.02ch 0.02ch 0.02ch rgba(0,0,0,0.1), -0.02ch -0.02ch -0.02ch rgba(255, 255, 255, 0.1);
}

label#signLabel {
  grid-area: header;
}

textarea {

  grid-area: sign;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  0 100px 80px rgba(0, 0, 0, 0.12);

  max-width: 100vw;
  border-radius: 0.5rem;
  border: 0.25rem solid lightgray;
  resize: none;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  font-family: 'Permanent Marker', cursive;
  background-color: #fafafa;
  color: black;
  padding: 2rem 2rem;
  font-size: 1.27em;

  width: 100%;
  height: 100%;
}

textarea::placeholder {
  font-family: 'Permanent Marker', cursive;

  color: gray;
  text-decoration: none;
  border-style: none;
  border-color: transparent;
  overflow: auto;
  text-align: center;
}

textarea:focus {
  outline: none !important;
  background-color: #fdfdfd;
}

.blurred {
  color: transparent !important;
  user-select: none;

  text-shadow: 0 0 1rem rgba(0, 0, 0, 0.05),
  -0.3rem 0.3rem 1rem rgba(0, 0, 0, 0.1),
  -0.9rem 0.7rem 2rem rgba(0, 0, 0, 0.2),
  -2.3rem 1.9rem 4rem rgba(0, 0, 0, 0.4),
  0.3rem -0.3rem 1rem rgba(0, 0, 0, 0.1),
  0.9rem -0.7rem 2rem rgba(0, 0, 0, 0.2),
  2.3rem -1.9rem 4rem rgba(0, 0, 0, 0.4);
}

.preference {
  grid-area: prefs;
  display: grid;
  grid-gap: 0.25rem;
  grid-template-columns: 1fr;
  font-size: small;
}

form button {
  background-image: none;
  background-color: #fafafa;
  border: 0.25rem solid #e8e8e8;
  box-shadow: 0 0.2rem rgba(0, 0, 0, 0.33), 0 0.3rem rgba(0, 0, 0, 0.44);
  font-size: 0.75em;

}

form button:enabled:active {
  top: 0.2rem;
  box-shadow: 0 0.05rem rgba(0, 00, 0, 0.33), 0 0.2rem rgba(0, 0, 0, 0.44);
}

form button:disabled {
  top: 0.2rem;
  color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 0.0rem rgba(0, 00, 0, 0.33), 0 0.1rem rgba(0, 0, 0, 0.44);
}

form button.pressed {
  top: 0.2rem;
  box-shadow: 0 0.0rem rgba(0, 00, 0, 0.33), 0 0.1rem rgba(0, 0, 0, 0.44);
}

form button#shrugButton {
  grid-area: shrug;
}

form button[type=submit] {
  grid-area: submit;
}

.inSync:after {
  content: "👍"
}

.outOfSync:after {
  content: "✍️"
}

.errorSync:after {
  content: "👊";
}
</style>