<template>
  <div id="app">
    <nav>
      <ul>
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <router-link to="/rules">Rules</router-link>
        </li>
        <li>
          <router-link to="/game" v-show="user && user.uid && enrolled">Play</router-link>
        </li>
      </ul>
    </nav>

    <router-view></router-view>

    <footer>
      <span>Hive Mind Gon' Give It To Ya</span>
    </footer>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import {mapState} from "vuex";


export default {
  name: 'App',
  created() {
    firebase.auth().onAuthStateChanged(user => {
      // console.log('auth state changed', user)
      if (user) {
        this.$store.commit('UPDATE_USER', user)
      } else {
        this.$store.commit('UPDATE_USER', {})
        this.$store.dispatch('unbindScoresRef');
        this.$store.dispatch('unbindGameRef');
        this.$store.dispatch('unbindAnswerRef');
      }
    });
  },
  computed: {
    ...mapState({
      user: (state) => state.user || null,
      enrolled: (state) => state.game && state.game.teams && state.game.teams[state.user.uid]
    })
  },


}
</script>

<style scoped lang="css">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3rem auto 3rem;
  row-gap: 2rem;
  min-height: 100vh;
  justify-items: center;
}

#app > main > header {}

#app > footer {
  width: 100vw;
  background-image: radial-gradient(circle farthest-corner at 97.4% 91.4%, var(--theme-dark-color) 0%, var(--theme-darker-color) 82.4%);
  padding: 0.5rem 3.5rem;
  text-align: right;
  font-weight: bold;
  font-size: 1rem;
}

#app > nav {
  width: 100vw;
  background-image: radial-gradient(circle farthest-corner at 97.4% 91.4%, var(--theme-dark-color) 0%, var(--theme-darker-color) 82.4%);
  padding: 0.33rem 3.33rem;
  font-weight: bold;
  font-size: 1.5rem;
}

#app > nav a {
  text-decoration: none;
  color: white;
}

#app > nav a:hover {
  text-decoration: underline;
}

#app > nav > ul {
  display: grid;
  grid-template-columns: repeat(2, auto) 1fr;
  list-style: none;
  grid-gap: 2rem;
  text-decoration: none;

  height: 3rem;
}

#app > nav > ul > li {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
}

#app > nav > ul > li:last-child {
  justify-self: end;
}


#app > main {
  max-width: 960px;
  align-self: center;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 4rem;
  align-items: center;
  padding: 0 1rem;
}


</style>
