<template>
  <section>
    <form v-if="openForEnrollment" class="enrollmentContainer" @submit="enroll">
      <div v-if="enrolled">
        Je bent nu ingeschreven als:
      </div>
      <div v-else>
        Je bent nog niet ingeschreven.
        <h4>Vul hier de naam in die op het scorebord mag staan:</h4>
      </div>
      <label>
        <input type="text"
               placeholder="..."
               v-model="localDisplayName">
      </label>
      <label>
        <button type="submit" :disabled="!localDisplayName">
          <span v-if="!enrolled">Schrijf je in</span>
          <span v-else>Wijzig je teamnaam</span>
        </button>
      </label>
    </form>
    <div v-else>
      Momenteel kan je nog niet inschrijven. De quizmaster dient dit te activeren.
    </div>
  </section>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Enrollment",
  data: function () {
    return {
      localDisplayName: ""
    }
  },
  mounted() {
    this.localDisplayName = this.user.displayName;
  },
  methods: {
    enroll() {
      this.$store.dispatch('renameYourself', this.localDisplayName).then(() =>{
        this.$store.dispatch('enroll')
      })
    },

  },
  computed: {
    ...mapState({
      user: (state) => state.user || null,
      openForEnrollment: (state) => (state.game && state.game.open_for_enrollment) || false,
      enrolled: (state) => state.game && state.game.teams && state.game.teams[state.user.uid]
    })
  },
}
</script>

<style scoped>
form > label {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1rem;
}

form {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  margin-top: 1rem;
}

div > button:last-child {
  margin-top: 5rem;
}

form input[type=text] {
  font-size: 1.25rem;
  border-radius: .66rem;
  outline: none;
  border: none;
  background: #fafafa;
  padding: 0.5rem 0.5rem;
  text-align: center;
  color: black;
  font-weight: bold;
}

</style>