<template>
  <main>
    <header>
      <h3>Hive Mind & Friends presents: </h3>
      <h1 v-if="title">{{ title.title }}</h1>
      <h4 v-if="title">{{ Intl.DateTimeFormat('nl-BE', {dateStyle: 'full', timeStyle: 'long'}).format(Date.parse(title.date))}}</h4>
    </header>
    <ol>
      <li>
        <h3>Aanmelden</h3>
        <Login></Login>
      </li>
      <li v-if="user && user.uid && game && game.conference_link">
        <h3>Inbellen</h3>
        <div>Klik op de knop om je aan te melden op de </div>
        <a :href="game.conference_link" target="_blank">
        <button>Videocall</button>
      </a>
      </li>
      <li v-if="user && user.uid">
        <h3>Inschrijven</h3>
        <Enrollment></Enrollment>
      </li>
      <li v-if="user && user.uid && enrolled">
        <h3>Spelen</h3>
        <p>Via de videocall worden de vragen gesteld.</p>
        <p>Antwoorden vul je in op het bordje dat je zodadelijk te zien krijgt.</p>
        <p>Hoe minder mensen het juiste antwoord weten, hoe meer punten het waard is!</p>
        <p>Duidelijk? Klik dan op
          <router-link to="game">
            <button>Play</button>
          </router-link>
        </p>
        <h4>Veel Succes!</h4>
      </li>
    </ol>
  </main>
</template>

<script>
import Login from '@/components/sections/Login'
import Enrollment from "@/components/sections/Enrollment";
import {mapState} from "vuex";


export default {
  name: "Landing",
  components: {Enrollment, Login},
  computed: {
    ...mapState({
      title: (state) => state.title || '',
      user: (state) => state.user || null,
      game: state => state.game || null,
      enrolled: (state) => state.game && state.game.teams && state.game.teams[state.user.uid]

    })
  },
  beforeMount() {
    this.$store.dispatch('bindTitleRef');
    this.$store.dispatch('bindGameRef').then(() => {
      console.log('got game data')
    }).catch(() => {
      console.log('not logged in')
    });
  },
}
</script>

<style scoped>

ol {
  list-style: none;
  counter-reset: c;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 0.66rem;

}

ol > li {
  counter-increment: c;
  font-weight: normal;
  padding: 0 0 0 1rem;
}

ol > li::marker {
  content: counter(c);
  font-weight: bolder;
  font-size: 1.5rem;
}

button {
  width: clamp(8rem, 100vw, 16rem)
}

h4 {
  font-weight: normal;
  font-size: small;
}

</style>