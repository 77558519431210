import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'

import App from './App.vue'

import {vuexfireMutations, firebaseAction} from 'vuexfire'

import Game from '@/components/Game'
import Rules from '@/components/Rules'
import Landing from '@/components/Landing'
// import _ from "lodash";


import {auth, db} from './firebaseConfig'

Vue.use(Vuex);
Vue.use(VueRouter)


const store = new Vuex.Store({
    // setup the reactive todos property
    state: {
        title: "",
        answer: "",
        scores: {},
        game: {
            teams: {},
            current_question: null
        },
        user: {
            email: "",
            displayName: "",
            uid: ""
        },
    },

    mutations: {
        ...vuexfireMutations,
        UPDATE_USER(state, payload) {
            state.user.email = payload.email
            state.user.displayName = payload.displayName
            state.user.uid = payload.uid
        },
        UPDATE_ANSWER(state, payload) {
            state.answer = payload
        },
    },
    actions: {
        bindScoresRef: firebaseAction(context => {
            return context.bindFirebaseRef('scores', db.ref('scores'))
        }),
        unbindScoresRef: firebaseAction(({unbindFirebaseRef}) => {
            unbindFirebaseRef('scores')
        }),

        bindTitleRef: firebaseAction(context => {
            return context.bindFirebaseRef('title', db.ref('title'))
        }),
        unbindTitleRef: firebaseAction(({unbindFirebaseRef}) => {
            unbindFirebaseRef('title')
        }),

        bindGameRef: firebaseAction(context => {
            return context.bindFirebaseRef('game', db.ref('game'))
        }),
        unbindGameRef: firebaseAction(({unbindFirebaseRef}) => {
            unbindFirebaseRef('game')
        }),

        bindAnswerRef: firebaseAction(context => {
            return context.bindFirebaseRef('answer',
                db.ref('answers/' + context.state.user.uid))
        }),
        unbindAnswerRef: firebaseAction(({unbindFirebaseRef}) => {
            unbindFirebaseRef('answer')
        }),
        wipeAnswer: firebaseAction((context) => {
            return db.ref('answers/' + context.state.user.uid).remove()
        }),
        writeAnswer: firebaseAction((context, payload) => {
            return db.ref('answers/' + context.state.user.uid)
                .update({answer: payload, correct: false})
        }),
        renameYourself: firebaseAction((context, payload) => {
            auth.currentUser.updateProfile({displayName: payload}).then((result) => {
                console.log(result)
                console.log('changed username', context.state.user.displayName)
                context.commit('UPDATE_USER', auth.currentUser)
                return db.ref('game/teams/' + context.state.user.uid).set(context.state.user.displayName)
            }).catch((reason) => {
                console.log('something went wrong')
                console.error(reason)
            })
        }),
        async signOut({commit}) {
            commit('UPDATE_USER', null)
        },
        async signIn({commit}, payload) {
            commit('UPDATE_USER', payload)
        }
    }
})

const routes = [
    {path: '/game', component: Game, name: 'game'},
    {path: '/rules', component: Rules, name: 'rules'},
    {path: '/', component: Landing, name: 'home'},
]
const router = new VueRouter({
    routes // short for `routes: routes`
})

new Vue({
    store: store,
    router: router,
    render: h => h(App),
}).$mount('#app')
